import { getShortProfileForMeta } from '../../../../utilities/index';
import log from '@sstdev/lib_logging';
import lodash from 'lodash';
const { omit } = lodash;

export default {
    verb: 'willCreate',
    namespace: 'transaction',
    relation: 'issue',
    description: 'proactively adjust the applicable stock levels',
    prerequisites: [   // todo pull out common code?
        // we want the existing stock, in case it already exists
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'stock',
                type: 'find'
            },
            query: ({ data }) => {
                let { 'item:item': item, issue: { 'location:location': location } = {} } = data.newRecord;
                if (!location) {
                    location = data.newRecord['location:location'];
                }
                return {
                    'item:item._id': item._id,
                    'location:location._id': location._id,
                    'meta.deleted': { $exists: false }
                };
            }
        },
        // but get the item just in case the stock doesn't exist, and we need to create it.
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'item',
                type: 'find'
            },
            query: ({ data }) => {
                const { 'item:item': item } = data.newRecord;
                return {
                    _id: item._id,
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: willCreate
};

async function willCreate({ data, prerequisiteResults, dispatch, context }) {
    const [
        { result: [existingStock] = [] },
        { result: [item] }
    ] = prerequisiteResults;

    const currentTime = new Date().toISOString();
    const currentUser = getShortProfileForMeta(context);
    const meta = {
        createdBy: currentUser,
        createdTime: currentTime,
        modifiedBy: currentUser,
        modifiedTime: currentTime,
        serverModifiedTime: currentTime
    };

    if (existingStock) {
        // we already have stock, so we just need to update it.
        const quantity = existingStock.quantity - data.newRecord.quantity;

        await dispatch(
            {
                isNew: false,
                items: [
                    {
                        ...existingStock,
                        quantity,
                        meta: {
                            ...existingStock.meta,
                            modifiedBy: currentUser,
                            modifiedTime: currentTime,
                            serverModifiedTime: currentTime
                        }
                    }
                ]
            },
            {
                // using upsert to avoid the update going to the server:
                ...context,
                verb: 'upsert',
                namespace: 'item',
                relation: 'stock',
                type: undefined
            }
        );

    } else {
        // we need to create the stock
        let {
            issue: { 'location:location': location, 'location:building': building, 'location:company': company } = {}
        } = data.newRecord;
        // if the user did not explicitly selected a location, but reused it as "remembered", it is stored in the root
        // extract that out:
        if (!location) {
            location = data.newRecord['location:location'] || {};
        }
        if (!building) {
            building = data.newRecord['location:building'] || {};
        }
        if (!company) {
            company = data.newRecord['location:company'] || {};
        }

        const { _id, assetNo, locations = [], ...rest } = omit(item, '$loki', '_idx');

        const companyReorderInfo = locations.filter(l => l['location:company']._id === location._id);
        let reorderInfo = {};
        if (locations.length) {
            if (companyReorderInfo.length === 0) {
                log.warn(`item ${assetNo} is not available at ${company.title} ${building.title} ${location.title}`);
            } else if (companyReorderInfo.length === 1) {
                reorderInfo = companyReorderInfo[0];
            } else {
                reorderInfo =
                    companyReorderInfo.find(l => l['location:building']._id === building._id) ||
                    companyReorderInfo.find(l => !l['location:building']);
            }
        }

        await dispatch(
            {
                isNew: true,
                items: [
                    {
                        _id: data.newRecord._id,
                        title: `${assetNo} @ ${location._id}`,
                        quantity: -1 * data.newRecord.quantity,
                        'item:item': { _id, assetNo },
                        ...rest,
                        ...reorderInfo,
                        'location:company': company,
                        'location:building': building,
                        'location:location': location,
                        meta
                    }
                ]
            },
            {
                // using upsert to avoid the update going to the serve
                ...context,
                verb: 'upsert',
                namespace: 'item',
                relation: 'stock',
                type: undefined
            }
        );
    }
    return data;
}
