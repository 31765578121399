import { Link, Text, View, fromTheme, webOnlyStyles, nativeOnlyStyles, Pressable, styled, Caret } from 'lib_ui-primitives';
import Collapse from '../helpers/Collapse';

export const NoShrink = styled(View)`
    flex-shrink: 0;
    flex-direction: column;
`;

export let Noncollapsible = styled(View).attrs({ name: 'noncollapsible' })`
    background-color: ${({ isActive, theme }) => {
        if (isActive) {
            return theme.outerMenus.navHeaderBackgroundActive;
        }
        return theme.outerMenus.navHeaderBackground;
    }};
`;
Noncollapsible.displayName = 'Noncollapsible';
Noncollapsible = webOnlyStyles(Noncollapsible)`
    &:hover {
        background-color: ${fromTheme('outerMenus', 'navHeaderBackgroundHover')};
    }
`;

export let NavHeaderPlain = styled(Pressable).attrs({ name: 'nav-header-plain' })`
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    justify-content: flex-start;
    letter-spacing: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6px;
    padding-right: 6px;
    background-color: ${fromTheme('outerMenus', 'navHeaderBackground')};
    border-right-color: ${fromTheme('outerMenus', 'navHeaderBorder')};
`;
NavHeaderPlain = webOnlyStyles(NavHeaderPlain)`
    cursor: pointer;
    &:hover {
        background-color: ${fromTheme('outerMenus', 'navHeaderBackgroundHover')};
        border-right-color: ${fromTheme('outerMenus', 'navHeaderBorderHover')};
    }
`;

export let DisabledNavHeader = styled(View).attrs({ name: 'DisabledNavHeader' })`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    justify-content: flex-start;
    letter-spacing: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6px;
    flex-grow: 0;
    background-color: ${fromTheme('outerMenus', 'navHeaderDisabledBackground')};
    border-right-color: ${fromTheme('outerMenus', 'navHeaderDisabledBorder')};
`;
DisabledNavHeader = webOnlyStyles(DisabledNavHeader)`
    cursor: no-drop;
`;
DisabledNavHeader.displayName = 'DisabledNavHeader';

export let NavHeaderLink = styled(Link).attrs({ name: 'nav-header-link' })`
    display: flex;
    flex-shrink: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6px;
    letter-spacing: 0;
    flex-grow: 1;
    background-color: fromTheme('outerMenus', 'navHeaderLinkBackground');
    border-right-color: fromTheme('outerMenus', 'navHeaderLinkBorder');
    text-decoration-line: none;
    border-bottom-color: 'transparent';
`;
NavHeaderLink = webOnlyStyles(NavHeaderLink)`
    &:hover {
        background-color: ${fromTheme('outerMenus', 'navHeaderLinkBackgroundHover')};
        border-right-color: ${fromTheme('outerMenus', 'navHeaderLinkBorderHover')};
    }
`;
NavHeaderLink = nativeOnlyStyles(NavHeaderLink)`
    border-bottom-width: 0;
`;

export const ActiveNavHeaderLink = styled(NavHeaderLink).attrs({ name: 'active-nav-header-link' })`
    background-color: ${fromTheme('outerMenus', 'navHeaderLinkBackgroundActive')};
    border-right-color: ${fromTheme('outerMenus', 'navHeaderLinkBorderActive')};
`;

export let LinkText = styled(Text)`
    font-family: ${fromTheme('fontAlternate')};
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: ${fromTheme('outerMenus', 'fontColor')};
`;

// Component referencing (as used here) is not supported in react native.
// This checks whether the link test is used by different types of nav headings
// and adjusts the styles accordingly.
LinkText = webOnlyStyles(LinkText)`
    ${ActiveNavHeaderLink} & {
        color: ${fromTheme('outerMenus', 'fontColorActive')};
    }
    ${NavHeaderPlain}:hover & {
        color: ${fromTheme('outerMenus', 'fontColorHover')};
    }
    ${NavHeaderLink}:hover & {
        color: ${fromTheme('outerMenus', 'fontColorHover')};
    }
`;

export let DisabledLinkText = styled(LinkText).attrs({ name: 'DisabledLinkText' })`
    color: ${fromTheme('outerMenus', 'disabledFontColor')};
`;
DisabledLinkText.displayName = 'DisabledLinkText';

export const CollapseOffset = styled(View).attrs({ name: 'collapse-offset' })`
    margin-left: 24px;
`;

export const FullWidthCollapse = styled(Collapse).attrs({ name: 'full-width-collapse' })`
    flex-grow: 1;
    flex-direction: column;
`;

// Use ArrowIcon SVG here instead of a material icon to avoid problem where
// the initial load displays before material icons are downloaded (which
// resulted in this briefly displaying text instead of the icon).
export let CollapseIndicator = styled(Caret).attrs({ name: 'collapse-indicator' })`
    margin-right: 6px;
    width: 8px;
    height: 8px;
    stroke: ${fromTheme('outerMenus', 'chevronColor')};
`;
CollapseIndicator.displayName = 'CollapseIndicator';
CollapseIndicator = webOnlyStyles(CollapseIndicator)`
    cursor: pointer;
    ${NavHeaderPlain}:hover & {
        stroke: ${fromTheme('outerMenus', 'chevronColorHover')};
    }
`;

export const CollapseSpacer = styled(View).attrs({ name: 'collapse-spacer' })`
    flex-shrink: 0;
    margin-right: 6px;
    width: 8px;
    height: 8px;
`;
CollapseSpacer.displayName = 'CollapseSpacer';
